import React, { useState } from "react";
import { Route } from "react-router-dom";
import { Box, IconButton, Modal } from "@material-ui/core";
import useLiff from "../hooks/useAuth";
import { encode } from "../page/helpper";
import {
  checkProfile,
  myProfile,
  tracker,
  useAPIQuery,
} from "../hooks/queries";
import { API } from "../hooks/api";
import muiStyles from "../page/muiStyle";
import {
  getBranchID,
  getRequiredLoginWithVespaState,
  resetRequiredLoginWithVespaState,
  setRequiredLoginWithVespaState,
} from "../hooks/user";
import { useMutation } from "react-query";
import Logo from "../assets/Logo/logo.svg";

export const RouteWithPermission = ({ path, component: Component }) => {
  const classes = muiStyles();
  const { getProfile, getAccessToken } = useLiff();
  const [isOpenModal, setIsOpenModal] = useState();

  const encodedData = encode({
    accesstoken: getAccessToken,
    lineuserid: getProfile?.userId,
  });

  const mutation = useMutation({
    mutationFn: tracker,
  });

  const { data: checkProfileData } = useAPIQuery("checkProfile", () =>
    checkProfile(encodedData),
  );

  const { status } = useAPIQuery("myProfile", () => myProfile(encodedData), {
    onSuccess: (res) => {
      const reqiredLoginWithVespa =
        res?.profile?.vespistiid === "" && path !== "/vespaid_callback";
      setRequiredLoginWithVespaState(reqiredLoginWithVespa);
    },
  });

  if (
    checkProfileData?.detail === "profile_not_existing" &&
    path !== "/vespaid_callback"
  ) {
    window.location.href = API.LOGIN_WITH_VESPA_ID;
    return;
  }

  const reqiredLoginWithVespa =
    isOpenModal ?? getRequiredLoginWithVespaState() ?? false;

  return (
    <Route
      path={path}
      exact={true}
      component={() => (
        <>
          <Component />
          {status === "success" && (
            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={reqiredLoginWithVespa}
              style={{
                backdropFilter: "blur(5px)",
                backgroundColor: "rgba(71,76,72,0.7)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  p: 4,
                }}
              >
                <div
                  className={classes.circleButton}
                  style={{ position: "absolute", top: "8px", right: "8px" }}
                  onClick={() => {
                    const encodedData = encode({
                      accesstoken: getAccessToken,
                      code: "VespistiRegisterClose",
                      oaid: getBranchID(),
                    });
                    setIsOpenModal(false);
                    resetRequiredLoginWithVespaState();

                    mutation.mutate(encodedData);
                  }}
                >
                  <span className={classes.closeIcon}>✕</span>
                </div>

                <div className={classes.vespistiModal}>
                  <img src={Logo} alt="logo" />
                  <br />
                  <div className={classes.vespistiModalContnet}>
                    มี <span className={classes.greenColor}>Vespisti ID</span>{" "}
                    รึยัง ?
                    <br />
                    สร้างบัญชีเพื่อเข้าถึง <br />
                    ทุกบริการจาก Vespa
                  </div>
                  <a
                    className={classes.vespistiModalRegisterButton}
                    onClick={() => (window.location = API.LOGIN_WITH_VESPA_ID)}
                  >
                    สมัครเลย
                  </a>
                </div>
              </Box>
            </Modal>
          )}
        </>
      )}
    />
  );
};
